<template>
  <div class="page">
    <van-empty image="error" description="您没有权限访问当前页面">
      <van-button type="primary" plain @click="$router.back()"
        >返回上一页</van-button
      >
    </van-empty>
  </div>
</template>
<script>
import { hideWechatOptionMenu } from "@/utils/util";
import { defineComponent, onMounted } from "@vue/runtime-core";
export default defineComponent({
  setup() {
    onMounted(() => {
      hideWechatOptionMenu();
    });
  },
});
</script>
